<template>
        <div v-if="!loading">
            <div class="text-center">
                <b-card-title>
                  <h3 >
                    <b-badge variant="success" class="p-2">
                         Jewels Available: {{numberWithCommas(totalJewels)}} 
                    </b-badge>
                  </h3>
                        </b-card-title>
            </div>
             <b-overlay
      :show="loadingJ"
      rounded="sm"
      opacity="1.00"
       variant="transparent"
    >
        <b-card class="mt-2 mb-5">
            <b-card-header class="p-1">
                <div>
                <b-card-title>Convert Goblets to Jewels <feather-icon class="text-danger"
                 v-b-tooltip.hover.v-danger
                  title="Earn for every 40 Goblets 1 Jewel "
                   icon="AlertCircleIcon" size="18" />
                </b-card-title>
                </div>
                 <div>
                <b-card-title class="text-sm-end text-danger">Total Goblets: {{totalgoblets}}</b-card-title>
                </div>
            </b-card-header>
    <b-row>
    <b-col lg="6" class="mb-1 mt-1">
      <b-input-group>
        <b-form-input 
        v-model="goblets"
        type="number" 
        placeholder="Quantity of Goblets" />
        <b-input-group-append>
          <b-button variant="outline-primary" @click="convertGoblets" :disabled="!goblets?true:goblets<=totalgoblets?false:true">
            <feather-icon
            icon="ChevronsRightIcon"/>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col lg="6" class="mt-1">
      <b-input-group>
        <b-form-input 
        v-model="jewels"
         placeholder="Quantity of Jewels"
         readonly />
      </b-input-group>
    </b-col>
  </b-row>
  <b-row class="justify-content-center">
    <b-col cols="12" xl="3" lg="6" md="12" sm="12" class="mt-4">
          <b-button block pill type="submit" class="backgroundBtn" :disabled="!jewels || !goblets || totalJewels==0 ?true:false" @click="sendClaimJewels">
                    Claim Jewels
                  </b-button>
    </b-col>
  </b-row>
        </b-card>
             </b-overlay>
        </div>
 
    <div v-else class="text-center mt-5">
         <b-spinner variant="success"
           style="width: 3rem; height: 3rem;" />
    </div>
</template>
<script>
import {BCard,BRow,BCol,BButton,BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend,
BCardHeader,BCardTitle,BCardText,VBTooltip,BSpinner,BOverlay,BBadge} from "bootstrap-vue"
import SellerService from "@/services/SellerService"
import Ripple from "vue-ripple-directive"
export default {
    directives:{
         'b-tooltip': VBTooltip,
          Ripple,
    },
    components:{BCard,BRow,BCol,BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend,BButton,
    BCardHeader,BCardTitle,BCardText,BSpinner,BOverlay,BBadge},
    data(){
        return{
            totalgoblets:null,
            goblets:null,
            jewels:null,
            loading:true,
            loadingJ:false,
            info:{
                wallet:null,
                amountJewel:null,
                amountGoblets:null
            },
            totalJewels:null
        }
    },
    mounted(){
        this.getExpbyUser()
        this.getProfileUser()
        this.getTotalJewelsAvailable()
    },
    methods:{
         getExpbyUser: async function () {
      const ex = (
        await SellerService.getExpbyUser(
          JSON.parse(localStorage.getItem("auth")).discordId
        )
      ).data;
      this.totalgoblets = ex.goblets;
    },
       getProfileUser:async function(){
      const ex=(await SellerService.getProfile( JSON.parse(localStorage.getItem("auth")).discordId)).data
      if(ex){
        this.info.wallet=ex.walletinvitation;
      }
    },
    getTotalJewelsAvailable:async function(){
        const totalj=(await SellerService.getTotalJewelsAvailable( JSON.parse(localStorage.getItem("auth")).discordId)).data
        this.totalJewels=totalj
         this.loading=false
    },
        convertGoblets:function(){
            this.jewels=this.goblets/40;
            this.info.amountJewel=this.jewels
            this.info.amountGoblets=this.goblets
        },

        sendClaimJewels:function(){
               this.$swal({
            title: "Are you sure?",
            text: "The Jewels to be claimed will be sent to wallet "+this.info.wallet+". If you want to change the wallet you must go to the profile section and update it. ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Claim Jewels",
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async(result) => {
            if (result.value) {
               this.loadingJ=true
              const ex=(await SellerService.sendClaimJewels(JSON.parse(localStorage.getItem("auth")).discordId,this.info)).data
              if (ex.data) {
                this.loadingJ=false
                 this.$swal({
            icon: 'success',
            title: 'Great!',
            text: 'The Jewels has been shipped successfully.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
         this.getExpbyUser()
          this.goblets=null
          this.jewels=null    
              }
              else if(this.loadingJ && ex.error){
                this.loadingJ=false;
                this.$swal({
          title: "Warning",
          text: "Something went wrong please check your wallet",
          icon: "warning",
          confirmButtonText: "Go to Profile",
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            this.$router.replace({ path: "/profile" });
          }
        });
              } 
            }
          });

       
              
        },

         numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.backgroundBtn {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
  padding: 18px 80px;
}
</style>